<!--
 * @Author: jiang
 * @Date: 2021-07-08 10:59:09
 * @Description:
-->
<template>
  <div style="width: 100%; height: 100%; padding: 0 15px 15px;">
    <div class="dept-page">
      <!-- 分类 -->
      <div class="nav-section">
        <nav-view></nav-view>
      </div>
      <!-- 成绩单 -->
      <div class="chart-section  g-bg">
        <work-view></work-view>
      </div>
      <!-- 部门待办 -->
      <div class="task-section  g-bg">
        <backlog-view></backlog-view>
      </div>
      <!-- 人员 -->
      <div class="user-section  g-bg">
        <member-view></member-view>
      </div>
    </div>
  </div>
</template>

<script>
import WorkView from './components/work.vue'
import BacklogView from './components/backlog.vue'
import MemberView from './components/member.vue'
import NavView from './components/nav.vue'
export default {
  components: {
    NavView,
    WorkView,
    BacklogView,
    MemberView,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.dept-page {
  display: grid;
  grid-template-areas:
    'nav nav nav'
    'chart task user';
  grid-template-rows: 120px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  width: 100%;
  height: 100%;

  .nav-section {
    grid-area: nav;
    overflow: hidden;
  }

  .chart-section {
    grid-area: chart;
    overflow: hidden;
  }

  .task-section {
    grid-area: task;
    overflow: hidden;
  }

  .user-section {
    grid-area: user;
    overflow: hidden;
  }
}
</style>
