<template>
  <b-section-scroll title="成员">
    <div
      v-if="list.length === 0"
      class="g-empty"
    ></div>
    <div class="list">
      <div
        class="item"
        v-for="item in list"
        :key="item.id"
      >
        <el-dropdown
          placement="bottom"
          trigger="click"
          @command="onCommand($event, item)"
        >
          <el-avatar
            :src="item.avatar"
            :size="46"
          ></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div style="width: 55px;">发布任务</div>
            </el-dropdown-item>
            <!-- <el-dropdown-item>详情</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
        <div class="name">{{item.name}}</div>
      </div>
    </div>
  </b-section-scroll>
</template>

<script>
import { getUserList } from '@/api/user'

export default {
  data() {
    return {
      list: [],
    }
  },
  computed: {
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const params = {
        department_id: this.deptId,
      }
      getUserList(params).then(res => {
        this.list = res
      })
    },
    onCommand(command, row) {
      this.$router.push({ path: '/task/create', query: { userId: row.id } })
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;

    .name {
      font-size: 14px;
      color: #2b2d42;
    }
  }
}
</style>
